.levelup__container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    width: 100%;
    height: 100%;

    
}

.levelup__title{
    font-size: 5rem;
    padding-bottom: 1.5rem;

}

.levelup__titlesub{
    font-size: 3rem;
}

.levelup__button{
    background-color: #38c400;
    max-width: 40rem;
    width: 40rem;
    height: 8rem;
    font-size: 3.5rem;
    border-radius: 10%;
    border: none;
    margin-bottom: 5%;
    margin-top: 2%;
}

.levelup__button:hover{
    background-color: #38c400bd;
    transition: 0.12s;

}

.levelup__snail{
    width: 100%;
    height: 90%;
}

.levelup__shell{
    width: 25%;
}

.levelup__swordfish{
    width: 40%;
}


.levelup__prego{
    width: 20%;
    height: 70%;
}

.levelup__text{
    font-size: 2rem;
}



.elements{

    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 3rem;
    width: 100%;
    height: 100%;
    display: block;

}